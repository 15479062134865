body {
  background-color: #000102;
  font-family: 'Roboto', 'Tahoma', Arial;
}

.App {
  text-align: center;
  width: 1000px;
  margin: auto;
}


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo-container {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App-header {
  position: relative;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-logo-container {
  z-index: 5;
  margin-top: -26px;
  position: absolute;
  top: 0px;
  margin-left: -66px;
  transform: scale(0.85);
  /* animation: 1.1s shake infinite alternate;
  animation: 1.2s shake infinite alternate; */
  animation: float 6s ease-in-out infinite;
}

.App-logo-container:mouse-enter {
  animation: 1.1s shake ease-in-out infinite alternate,
             1.2s shake ease-in-out infinite alternate;
  opacity: 1;
}

.App-logo-container:hover .App-ball {
  animation: 0;
  box-shadow:
  0 0 500px rgb(40, 0, 201),
  0 0 500px rgb(40, 0, 201),
  0 0 500px rgb(40, 0, 201),
  0 0 500px rgb(40, 0, 201),
  0 0 500px rgb(40, 0, 201),
  0 0 500px rgb(40, 0, 201),
    -20px 0px 180px rgb(67, 0, 224),
    20px 0px 180px rgb(34, 0, 255),
    inset 0 0 50px rgb(41, 21, 224),
    inset 50px 0 80px rgb(255, 255, 255),
    inset -50px 0 80px rgb(255, 255, 255),
    inset 50px 0 300px rgb(255, 255, 255),
    inset -50px 0 300px rgb(255, 255, 255) !important;
}

.App-logo {
  position: absolute;
  top: 220px;
  margin-left: -180px;
  z-index: 2;
  pointer-events: none;
  /* animation: 0.9s shake 30s infinite alternate;
  animation: 1.1s shake 30s infinite alternate; */
}

.App-ball {
  position: absolute;
  transform: scale(0.36);
  margin-left: -237px;
  top: 171px;
  z-index: 1;
  opacity: 1;
  width: 471px;
  height: 200px;
  border-radius: 50%;
  box-shadow:
  0 0 500px rgb(40, 0, 201),
  0 0 500px rgb(40, 0, 201),
  0 0 500px rgb(40, 0, 201),
    -20px 0px 180px rgb(67, 0, 224),
    20px 0px 180px rgb(34, 0, 255),
    inset 0 0 50px #fff,
    inset 50px 0 80px rgb(43, 0, 255),
    inset -50px 0 80px rgb(21, 0, 155),
    inset 50px 0 300px rgb(255, 255, 255),
    inset -50px 0 300px rgb(255, 255, 255);
  animation: pulsate 20s ease-in-out infinite;
}

@keyframes shake {
  1% { transform: skewX(-1deg) scale(0.85) translatey(0px); }
  2% { transform: skewX(2deg) scale(0.85) translatey(0px); }
  3% { transform: skewX(-3deg) scale(0.85) translatey(0px); }
  5% { transform: skewX(2deg) scale(0.85) translatey(0px); }
  8% { transform: skewX(0deg) scale(0.85) translatey(0px); }
  13% { transform: skewX(0deg) scale(0.85) translatey(0px); }  
}

@keyframes float {
	0% {
		transform: translatey(0px) scale(0.85);
	}
	50% {
		transform: translatey(-2px) scale(0.85);
	}
	100% {
		transform: translatey(0px) scale(0.85);
	}
}

@keyframes pulsate {
  10% {
    box-shadow:
    0 0 500px rgb(40, 0, 201),
    0 0 500px rgb(40, 0, 201),
    0 0 500px rgb(40, 0, 201),
      -20px 0px 180px rgb(67, 0, 224),
      20px 0px 180px rgb(34, 0, 255),
      inset 0 0 50px #fff,
      inset 50px 0 80px rgb(43, 0, 255),
      inset -50px 0 80px rgb(21, 0, 155),
      inset -50px 0 300px rgb(255, 255, 255),
      inset -50px 0 300px rgb(255, 255, 255);
  }

  25% {
    box-shadow:
    0 0 500px rgb(7, 0, 212),
    0 0 500px rgb(7, 1, 172),
    0 0 500px rgb(7, 1, 172),
      20px 0 180px rgb(14, 94, 234),
      -20px 0 180px rgb(0, 98, 255),
      inset 0 0 50px #fff,
      inset -50px 0 80px rgb(0, 34, 255),
      inset 50px 0 80px rgb(0, 30, 255),
      inset 50px 0 300px rgb(70, 55, 237),
      inset 50px 0 300px rgb(70, 55, 237);
  }

  50% {
    box-shadow:
    0 0 500px rgb(255, 255, 255),
    0 0 500px rgb(255, 255, 255),
    0 0 500px rgb(255, 255, 255),
      -20px 0 180px rgb(14, 94, 234),
      20px 0 180px rgb(0, 98, 255),
      inset 0 -250px 250px #fff,
      inset -50px 0 80px rgb(0, 34, 255),
      inset 50px 0 80px rgb(0, 30, 255),
      inset 50px 0 300px rgb(13, 3, 124),
      inset 50px 0 300px rgb(13, 3, 124);
  }

  60% {
    box-shadow:
    0 0 500px rgba(255, 255, 255, 1),
    0 0 500px rgba(255, 255, 255, 1),
    0 0 500px rgba(255, 255, 255, 1),
      -20px 0 180px rgb(14, 94, 234),
      20px 0 180px rgb(0, 98, 255),
      inset 0 -250px 250px #fff,
      inset -50px 0 80px rgb(0, 34, 255),
      inset 50px 0 80px rgb(0, 30, 255),
      inset 50px 0 300px rgb(255, 255, 255),
      inset 50px 0 300px rgb(255, 255, 255);
    opacity: 0.75;
  }
}

@keyframes pulsate-simple {

  50% {
    box-shadow:
    0 0 500px rgb(7, 0, 212),
    0 0 500px rgb(7, 1, 172),
    0 0 500px rgb(7, 1, 172),
      20px 0 180px rgb(14, 94, 234),
      -20px 0 180px rgb(0, 98, 255),
      inset 0 0 50px #fff,
      inset -50px 0 80px rgb(0, 34, 255),
      inset 50px 0 80px rgb(0, 30, 255),
      inset 50px 0 300px rgb(70, 55, 237),
      inset 50px 0 300px rgb(70, 55, 237);
  }
}

.menu {
  z-index: 5;
  display: flex;
  align-items: center;
  background: rgba(2, 17, 38, 0.2);
  padding: 2px;
  margin: 10px;
  transform: skewX(-17deg);
  border-radius: 5px;
  margin-top: 300px;
  box-shadow:
  0 0 500px rgb(40, 0, 201, 0.2),
  0 0 500px rgb(40, 0, 201, 0.2),
  0 0 500px rgb(40, 0, 201, 0.2),
    -20px 0px 180px rgb(67, 0, 224, 0.2),
    20px 0px 180px rgb(34, 0, 255),
    inset 0 0 50px rgb(255, 255, 255, 0.2),
    inset 50px 0 80px rgb(43, 0, 255),
    inset -50px 0 80px rgb(21, 0, 155),
    inset 50px 0 300px rgb(255, 255, 255, 0.2),
    inset -50px 0 300px rgb(255, 255, 255, 0.2);
}

.menu:hover button {
  color: rgb(73, 68, 160)
}

.menu:hover button:hover {
  color: #fff
}

button {
  border: 0;
  border-radius: 5px;
  padding: 12px 20px;
  background: linear-gradient(rgb(24, 26, 135), rgb(0, 8, 80));
  color: white;
  font: 16px 'Roboto', 'Arial';
  margin: 0 2px 0 0; 
  float: left;
  cursor: pointer;
  transition: 0.3s;
  text-transform: uppercase;
  font-weight: bold;
}

button:last-child {
  margin: 0;
}

button:hover {
  color: white;
  background: linear-gradient(rgb(1, 17, 160), rgb(0, 8, 80));
  transform: scale(1.25);
  box-shadow:
  0 0 5px rgb(40, 0, 201, 0.5),
    -20px 0px 50px rgba(4, 22, 143, 0.2),
    20px 0px 50px rgb(15, 1, 106);
}

button:active {
  background: linear-gradient(rgb(0, 8, 80), rgb(1, 17, 160));
  transform: scale(0.9);
  box-shadow:
  0 0 20px rgb(40, 0, 201, 0.2);
}

.content {
  z-index: 5;
}

.box {
  width: 895px;
  z-index: 5;
  font-family: 'Roboto', 'Tahoma';
  text-align: left;
  background: rgb(26, 18, 69);
  color: rgb(255, 255, 255);
  margin: 50px auto;
  border-radius: 7px;
  padding: 2px;
  box-shadow:
  0 0 500px rgb(40, 0, 201, 0.2),
  0 0 500px rgb(40, 0, 201, 0.2),
  0 0 500px rgb(40, 0, 201, 0.2),
    -20px 0px 180px rgb(67, 0, 224, 0.2),
    20px 0px 180px rgb(34, 0, 255),
    inset 0 0 50px rgb(255, 255, 255, 0.2),
    inset 50px 0 80px rgb(43, 0, 255),
    inset -50px 0 80px rgb(21, 0, 155),
    inset 50px 0 300px rgb(255, 255, 255, 0.2),
    inset -50px 0 300px rgb(255, 255, 255, 0.2);
}

.box-header {
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: 2px;
  background: linear-gradient(rgb(24, 26, 135, 0.7), rgb(0, 8, 80, 0.7));
  /* background-image: linear-gradient(45deg, rgb(115, 43, 247) 0%, rgb(125, 48, 248) 13%, rgb(134, 52, 249) 19%, rgb(143, 57, 249) 23%, rgb(155, 66, 250) 27%, rgb(164, 70, 251) 31%, rgb(172, 75, 251) 34%, rgb(180, 79, 252) 38%, rgb(188, 83, 253) 41%, rgb(196, 88, 253) 44%, rgb(203, 93, 254) 47%, rgb(212, 102, 255) 50%, rgb(216, 117, 255) 53%, rgb(222, 133, 255) 56%, rgb(226, 148, 255) 59%, rgb(231, 158, 255) 62%, rgb(236, 173, 255) 66%, rgb(240, 189, 255) 69%, rgb(243, 199, 255) 73%, rgb(246, 214, 255) 77%, rgb(250, 230, 255) 81%, rgb(252, 240, 255) 87%, rgb(255, 255, 255) 100%); */
}

.box-inner {
  padding: 20px;
  background:rgb(0, 5, 45);
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.box p {
  font-size: 16px;
}

footer {
  width: 100%;
  font-size: 14px;
  font-style: italic;
  color:rgb(0, 98, 255);
  text-align: center;
  margin-left: -17px;
  margin-bottom: 300px
}

a { 
  color:rgb(0, 98, 255); 
  text-decoration: none;
  margin: 0 5px;
}